import * as React from 'react';
import Layout from '../components/layouts/layout';

const WorksPage = () => {
  return (
    <Layout pageTitle="Works">
      <div
        className="
          w-full
          sm:px-10
          sm:max-w-screen-sm"
      >
        <details>
          <summary>
            starshare
            <a
              href="https://starshare.ipfscrosspoint.net/#/upload"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              :link
            </a>
          </summary>
          <p className="ml-4">
            IPFSを介してファイルを共有するWebアプリケーション
          </p>
          <ul>
            <li>
              <a
                href="https://github.com/ocknamo/star-share#starshare"
                target="_blank"
                rel="noopener noreferrer"
              >
                github
              </a>
            </li>
          </ul>
        </details>
        <details>
          <summary>
            selfhostingengine
            <a
              href="https://selfhostingengine.ipfscrosspoint.net/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              :link
            </a>
          </summary>
          <p className="ml-4">
            IPFSに自分自身をホスティングするWebアプリケーション
          </p>
          <ul>
            <li>
              <a
                href="https://github.com/ocknamo/self-hosting-engine#selfhostingengine"
                target="_blank"
                rel="noopener noreferrer"
              >
                github
              </a>
            </li>
            <li>
              <a
                href="https://zenn.dev/ocknamo/articles/23a25ae6f8d9be "
                target="_blank"
                rel="noopener noreferrer"
              >
                自分で自分をホスティングするWebアプリケーション1：
                コンセプト｜zenn{' '}
              </a>
            </li>
            <li>
              <a
                href="https://zenn.dev/ocknamo/articles/5c06f9df88121c"
                target="_blank"
                rel="noopener noreferrer"
              >
                自分で自分をホスティングするWebアプリケーション2： 実装｜zenn{' '}
              </a>
            </li>
          </ul>
        </details>

        <details>
          <summary>
            OnePunchManBot
            <a
              href="https://mobile.twitter.com/onepanchbot"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              :link
            </a>
          </summary>
          <p className="ml-4">
            ONEさんの漫画ワンパンマンの更新をお知らせするtwitterBOT.
          </p>
        </details>
      </div>
    </Layout>
  );
};

export default WorksPage;
